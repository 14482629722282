import React from 'react'

import {
  Flex,
  Box,
  Card,
  Heading
} from 'rebass'

import Layout from '../components/layout'
import Container from '../components/container'
import Header from '../components/header'

const WebDevelopmentPage = () => (
  <Layout>
    <Container>
      <Header roleTitle='developers' />

      <Flex mx={-2} p={4}>
        <Box px={2} width={1/3}>
          <Card p={2} borderRadius={5} border='5px dotted' borderColor='cashmere'>
            <Heading color='cashmere'>MajorClarity</Heading>
          </Card>
        </Box>
      </Flex>
    </Container>
  </Layout>
)

export default WebDevelopmentPage
